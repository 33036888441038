import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`All Diploma students were involved  in the CAS Programme. This year they participated in sustained, collaborative projects such as Kindergarten building, Down Syndrome centre support, Animal shelter, Student store, Visually impaired centre support, Djerela, Kindergarten English Teaching, Inclusive Education  and Children of the World to Children of Ukraine projects, Music band, and run a Tutoring Centre.  Students set challenging goals, planned their activities and  learned new skills.  Students worked in cooperation with PTA, and three projects received PTA CISRI grants. During the school closure students initiated a Corona KIndness project, gave online classes and worked on individual goals in three CAS areas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      